import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, DestroyRef, inject, Pipe, PipeTransform } from '@angular/core';
import { I18nData, LANG, LANG_DEFAULT } from '@scb-lib/models';
import { UiService } from '@shared/services/ui.service';

@Pipe({
	name: 'i18nDataRead',
	standalone: true,
})
export class I18nDataReadPipe implements PipeTransform {
	destroyRef = inject(DestroyRef);
	cd = inject(ChangeDetectorRef);
	uiSrv = inject(UiService);
	asyncPipe = new AsyncPipe(this.cd);

	transform(prop: I18nData<unknown> | undefined, lang: LANG): string {
		// return this.getValue(prop, this.asyncPipe.transform(this.uiSrv.curLangSgn$));
		return this.getValue(prop, lang);
		// return this.uiSrv.curLangSgn$.pipe(
		// 	takeUntilDestroyed(this.destroyRef),
		// 	map((curLang) => this.getValue(prop, curLang))
		// );
	}

	getValue(prop: I18nData<unknown> | undefined, curLang: LANG): string {
		if (prop?.[curLang]) {
			return prop[curLang] as string;
		}
		return (prop?.[LANG_DEFAULT] as string) ?? '';
	}
}
